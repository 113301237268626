const CartTotal = ({ cartItems, handleBuy, removeAllItems }) => {
  const calculateTotal = () => {
    return cartItems.reduce(
      (total, item) =>
        total + Number(item.precio_uni).toFixed(2) * item.quantity,
      0
    );
  };

  return (
      <div
        className="row"
        style={{ paddingTop: "12px", position: "absolute", bottom: "0", width:"100%" }}
      >
        <div className="col-md-5 col-12">
          <p className="txtTotalPag">
            Total a pagar:{" "}
            {new Intl.NumberFormat("es-AR", {
              style: "currency",
              currency: "ARS",
            }).format(calculateTotal())}
          </p>
        </div>
        <div className="col-md-12 col-12" style={{paddingBottom:"10px", display:"flex", justifyContent:"right", paddingRight:"0"}}>
            <div
            className="row"
              style={{width:"100%"}}
              /* style={{ display: "flex", justifyContent: "space-between", width:"100%"}} */
            >
              
              <div className="col-md-8 col-12 contBtnIniCom paddBtn" >
              <button onClick={handleBuy} className="btnCartCom">
                Comprar
              </button>
              </div>
              <div className="col-md-4 col-12 contBtnIniCom">

              <button onClick={removeAllItems} className="btnCartVac">
                Vaciar Carrito
              </button>
              </div>
            </div>
          
        </div>
      </div>
  );
};

export default CartTotal;
