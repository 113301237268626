import React, { useState, useEffect, useRef } from "react";
import CartItemsList from "./Cart/CartItemsList";
import CartTotal from "./Cart/CartTotal";
import SearchBar from "./Search/SearchBar";
import ProductList from "./Products/ProductList";
import Header from "./Header/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faLeftLong } from "@fortawesome/free-solid-svg-icons";
import ContenedorBanners from "./ContenedorBanners/ContenedorBanners";
import axios from "axios";
import Swal from "sweetalert2";
import Navbarr from "../Components/nav/NavBar";
import { URL_API } from "./urlApi/urlApi";
import Offcanvas from "react-bootstrap/Offcanvas";
import Footer from "./Footer/Footer";
import CategoryButtons from "./Buttons/CategoryButtons ";
//import io from "socket.io-client";
import NoItemsBanner from "./cartelNoProduct/NoItemsBanner";
import ErrorItemsBanner from "./cartelNoProduct/ErrorItemsBanner";

/* const socket = io("https://zhinversiones.com.ar", {
  path: "/socket.io",
}); */

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("todos");
  const [selectedFilter, setSelectedFilter] = useState("todos");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [datosIng, setDatosIng] = useState([]);
  const [data, setData] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [btnVisible, setBtnVisible] = useState(false);
  const [textoCalc, setTextoCalc] = useState("");
  const [urlBtn, setUrlBtn]= useState("");

  const urlParams = new URLSearchParams(window.location.search);
  const locParam = urlParams.get("loc");

  const actualizarVisibilidadTablas = (datos) => {
    const actualizaciones = datos.map((item) => {
      const [texto1, texto2] = item.descripcion.split('|');
      return {
        tipo: item.tipo,
        texto1: texto1 || '', 
        texto2: texto2 || '', 
        valor: parseInt(item.valor) !== 1, 
      };
    });

    // Actualizar el estado de visibilidad basado en las actualizaciones
    actualizaciones.forEach((item) => {
      switch (item.tipo) {
        case "oculta_btn_calc_quimicos_tienda":
          setBtnVisible(item.valor);
          setTextoCalc(item.texto1)
          setUrlBtn(encodeURI(`https://${item.texto2}`));
          break;
        // Agrega más casos según sea necesario
        default:
          break;
      }
    });
  };

  const urlMostrar =
    "https://www.zuppahermanos.com.ar/virtual/api/generico.php?accion=obtenerParametrosMuetraGrillasEstadisticas";

  const datosMostrar = async (e) => {
    try {
      const response = await axios.get(urlMostrar);
      console.log(response);
      actualizarVisibilidadTablas(response.data.message);
    } catch (error) {
      console.error("Error en la llamada a la API:", error);
    }
  };

  const fetchData = async () => {
    let codLocal = localStorage.getItem("codLocal");

    try {
      const response = await axios.post(
        URL_API,
        JSON.stringify({
          accion: "obtenerLocales",
          codigo: codLocal,
        })
      );
      if (response.status === 200) {
        let resp = response.data.message;
        setDatosIng(resp);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (locParam !== null) {
      localStorage.setItem("codLocal", locParam);
      fetchData();
    }
  }, [locParam]);

  useEffect(() => {
    if (datosIng.length > 0) {
      localStorage.setItem("nombre", datosIng[0].nombre_loc);
      localStorage.setItem("img_logo", datosIng[0].img_logo);
      localStorage.setItem("tiene_socios", datosIng[0].tiene_socios);
    }
  }, [datosIng]);

  useEffect(() => {
    const codigoG = Number(localStorage.getItem("codLocal"));
    const codigoV = Number(localStorage.getItem("codLocalOld"));

    if (codigoV === null) {
      localStorage.setItem("codLocalOld", codigoG);
    }

    if (codigoV !== codigoG) {
      localStorage.removeItem("cartItems");
      setTimeout(() => {
        localStorage.setItem("codLocalOld", codigoG);
      }, 800);
    }
    datosMostrar();
  }, []);

  /* useEffect(() => {
    // Evento personalizado del cliente al servidor
    socket.emit("localMsg", localStorage.getItem("codLocal"));

    // Manejar eventos del servidor al cliente

    socket.on("dataProducTienda", (message) => {
      //console.log(message);
      setData(message);
    });
    // Manejar evento de conexión exitosa
    socket.on("connect", () => {
      console.log("Conectado al servidor WebSocket");
      setConnectt(true);
    });

    // Manejar evento de desconexión
    socket.on("disconnect", () => {
      console.log("Desconectado del servidor WebSocket");
    });
  }, [conectt]); */

   const datosGrilla = async (e) => {
    let codLocal = localStorage.getItem("codLocal");

    try {
      await axios
        .post(
          URL_API,
          JSON.stringify({
            accion: "obtenerArticulosTienda",
            codArt: "0",
            codLocal: codLocal,
            codCategoria: "0",
            orderBy: "",
          })
        )
        .then((res) => {
          if (res.status === 200) {
            let resp = res.data.message;
            setData(resp);
          }
        });
    } catch (error) {}
  };

  useEffect(() => {
    if (localStorage.getItem("codLocal") !== null) {
      //socket.emit("localMsg", localStorage.getItem("codLocal"));
       datosGrilla();
    } else window.location.href = "./";
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      setDataLoaded(true);
    }
  }, [data]);

  const searchProducts = (term) => {
    const results = term
      ? data.filter((product) =>
          product.nom_art.toLowerCase().includes(term.toLowerCase())
        )
      : data;
    setSearchResults(results);
  };

  useEffect(() => {
    // Cargamos los datos desde el Local Storage al montar el componente
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems));
    }
    setIsLoaded(true); // Marcamos que los datos ya han sido cargados desde el Local Storage
  }, []);

  useEffect(() => {
    // Guardamos los datos en el Local Storage cada vez que cartItems cambie (excepto en el montaje inicial)
    if (isLoaded) {
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
    }
  }, [cartItems, isLoaded]);

  useEffect(() => {
    if (dataLoaded) {
      searchProducts(searchTerm);
    }
  }, [dataLoaded, searchTerm]);

  useEffect(() => {
    searchProducts(searchTerm);
  }, [data]);

  useEffect(() => {
    if (dataLoaded) {
      searchProducts("");
    }
  }, [dataLoaded]);

  const handleSearchTermChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
  };
  const toggleMenu = () => {
    setMenuOpen((menuOpen) => !menuOpen);
  };

  const handleShow = (e) => {
    e.preventDefault();
    window.location.href = "/FormEnv";
  };

  const handleTypeChange = (type) => {
    setSelectedType(type);
    setMenuOpen(false);
  };

  const handleButton = () => {
    window.open(`${urlBtn}`, '_blank');
  };


  const handleCartOpen = () => {
    setIsCartOpen((prevIsCartOpen) => !prevIsCartOpen);
  };
  const cartRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cartRef.current && !cartRef.current.contains(event.target)) {
        setIsCartOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const menuRef = useRef(null);
  useEffect(() => {
    const handleClickOutsideMenu = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideMenu);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideMenu);
    };
  }, []);

  const addToCart = (item, quantity) => {
    const quantityToAdd = parseInt(quantity, 10) || 1;
    const stkNum = Number(item.stk_disp);

    const existingItem = cartItems.find(
      (cartItem) => cartItem.idx === item.idx
    );

    if (existingItem) {
      if (existingItem.quantity + quantityToAdd <= stkNum) {
        setCartItems(
          cartItems.map((cartItem) =>
            cartItem.idx === item.idx
              ? { ...cartItem, quantity: cartItem.quantity + quantityToAdd }
              : cartItem
          )
        );
        setIsCartOpen(true);
      } else {
        Swal.fire({
          width: "25em",
          color: "#fff",
          background: "#2B2B2B",
          position: "center",
          icon: "warning",
          text: `
          No puede agregar más ${item.nom_art}, llego al límite de Stock Disponible en este momento`,
          showConfirmButton: true,
        });
        setIsCartOpen(false);
      }
    } else {
      if (quantityToAdd <= stkNum) {
        setCartItems([...cartItems, { ...item, quantity: quantityToAdd }]);
        setIsCartOpen(true);
      } else {
        Swal.fire({
          width: "25em",
          color: "#fff",
          background: "#2B2B2B",
          position: "center",
          icon: "warning",
          text: `No puede agregar mas ${item.nom_art} llego al limite de Stock`,
          showConfirmButton: true,
        });
        setIsCartOpen(false);
      }
    }
  };

  const removeFromCart = (item, quantity = 1) => {
    const existingItem = cartItems.find(
      (cartItem) => cartItem.idx === item.idx
    );
    const quantityToRemove = parseInt(quantity, 10) || 0;

    if (existingItem.quantity <= quantityToRemove) {
      setCartItems(cartItems.filter((cartItem) => cartItem.idx !== item.idx));
    } else {
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.idx === item.idx
            ? { ...cartItem, quantity: cartItem.quantity - quantityToRemove }
            : cartItem
        )
      );
    }
  };

  const removeAllFromCart = (item) => {
    setCartItems(cartItems.filter((cartItem) => cartItem.idx !== item.idx));
  };

  const removeAllItems = () => {
    setCartItems([]);
  };

  const handleQuantityChange = (e, product) => {
    const quantity = parseInt(e.target.value);
    product.quantity = quantity; // Actualizamos la cantidad directamente en el objeto del producto
  };

  const cartItemCount = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const handleOptionClickFl = () => {
    setSelectedFilter("borrar-filtros");
  };

  const handleBorrarSearch = () => {
    setSearchTerm("");
  };

  return (
    <div className="contMain">
      <Header handleCartOpen={handleCartOpen} cartItemCount={cartItemCount} txtCalcu={textoCalc} mostrarBtn={btnVisible} handleButton={handleButton}  />
      {
        <Offcanvas show={menuOpen} onHide={toggleMenu} placement="end">
          {
            <div className={`row cart-overlay`}>
              <div className={`col-12 cart-container`}>
                <div ref={menuRef} className="contCart">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "right",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "30px",
                        height: "20px",
                        position: "relative",
                        cursor: "pointer",
                        top: "5px",
                      }}
                      type="button"
                      onClick={toggleMenu}
                    >
                      <FontAwesomeIcon icon={faClose} className="iconX" />
                    </div>
                  </div>
                  <div>
                    <CategoryButtons
                      selectedType={selectedType}
                      handleTypeChange={handleTypeChange}
                      handleFilterChange={setSelectedFilter}
                      setMenuOpen={setMenuOpen}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
        </Offcanvas>
      }
      <Offcanvas show={isCartOpen} onHide={handleCartOpen} placement="end">
        {
          <div className={`row cart-overlay`}>
            <div className={`col-12 cart-container`}>
              <div ref={cartRef} className="contCart">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    position: "relative",
                    top: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "30px",
                      height: "20px",
                      position: "relative",
                      right: "15px",
                      cursor: "pointer",
                    }}
                    type="button"
                    onClick={handleCartOpen}
                  >
                    <FontAwesomeIcon icon={faClose} className="iconX" />
                  </div>
                </div>
                <div
                  style={{
                    paddingBottom: "15px",
                    paddingTop: "3px",
                    position: "relative",
                    left: "8px",
                    borderBottom: "1px solid #000",
                    width: "96%",
                  }}
                >
                  <span
                    style={{
                      color: "#000",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    Mi carrito
                  </span>
                </div>
                {cartItems.length === 0 ? (
                  <div
                    className="row classFlexCenterGen"
                    style={{ paddingTop: "25px" }}
                  >
                    <div className="col-12 classFlexCenterGen">
                      <span className="txtCartVa">
                        El carrito de compras está vacio.
                      </span>
                    </div>
                    <div className="col-12 classFlexCenterGen">
                      <a
                        type="button"
                        onClick={handleCartOpen}
                        style={{ color: "#000", textDecoration: "none" }}
                        className="txtVolTien"
                      >
                        <FontAwesomeIcon
                          icon={faLeftLong}
                          style={{ paddingRight: "5px" }}
                        />
                        Volver a la Tienda
                      </a>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="contItemsCart">
                      <CartItemsList
                        cartItems={cartItems}
                        removeFromCart={removeFromCart}
                        removeAllFromCart={removeAllFromCart}
                      />
                    </div>
                    <div>
                      <CartTotal
                        cartItems={cartItems}
                        handleBuy={handleShow}
                        removeAllItems={removeAllItems}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        }
      </Offcanvas>
      <ContenedorBanners />

      <div
        className="row"
        style={{ paddingRight: "0%", marginRight: "0%", width: "100%" }}
      >
        <div
          className="col-12 col-sm-12 col-lg-9 col-xl-12"
          style={{
            paddingRight: "0%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            onClick={() => {
              handleTypeChange("todos");
              handleOptionClickFl("borrar-filtros");
              handleBorrarSearch("");
            }}
            className="txtBorrarFil"
          >
            <h6>Borrar Filtros</h6>
          </button>
          <SearchBar
            searchTerm={searchTerm}
            handleSearchTermChange={handleSearchTermChange}
          />
        </div>
      </div>
      <div
        className="row"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="col-md-2 col-12 contNavvv">
          <Navbarr
            menuOpen={menuOpen}
            toggleMenu={toggleMenu}
            selectedType={selectedType}
            handleTypeChange={handleTypeChange}
            handleFilterChange={setSelectedFilter}
          />
          <button
            onClick={() => {
              handleTypeChange("todos");
              handleOptionClickFl("borrar-filtros");
              handleBorrarSearch("");
            }}
            className="txtBorrarFilDos"
          >
            <span>Borrar Filtros</span>
          </button>
        </div>
        <div className="col-md-10 col-12" style={{ paddingRight: 0 }}>
          {data && data.length > 0 ? (
            <ProductList
              searchResults={searchResults}
              selectedType={selectedType}
              selectedFilter={selectedFilter}
              handleQuantityChange={handleQuantityChange}
              addToCart={addToCart}
            />
          ) : data.length === 0 && data.status !== "200" ? (
            <NoItemsBanner />
          ) : (
            <ErrorItemsBanner />
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Cart;
