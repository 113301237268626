import React from 'react'

const ImgTranf = () => {
  return (
    <div>
        <img src={require("../assets/cbu_pc_villa_maria.jpg")} />
        <img src={require("../assets/cbu_tel_villa_maria.jpg")} />
        <img src={require("../assets/cbu_pc_gral_deheza.jpg")} />
        <img src={require("../assets/cbu_tel_gral_deheza.jpg")} />
    </div>
  )
}

export default ImgTranf