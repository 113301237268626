const ImgsTransfe = [
  {
    id: 1,
    cod_local: "1",
    img_cel:"https://tienda.zhvirtual.com.ar/static/media/cbu_zh_cel.bb1cf450b84e07adebdf.jpg",
    img_pc:"https://tienda.zhvirtual.com.ar/static/media/cbu_zh_pc.e66647eb710d8cdc3a4f.jpg"
  },{
    id: 2,
    cod_local: "2",
    img_cel:"https://tienda.zhvirtual.com.ar/static/media/cbu_tel_villa_maria.7af674b10096a46efb6d.jpg",
    img_pc:"https://tienda.zhvirtual.com.ar/static/media/cbu_pc_villa_maria.31e6c476820bc30514b6.jpg"
  },{
    id: 3,
    cod_local: "4",
    img_cel:"https://tienda.zhvirtual.com.ar/static/media/cbu_tel_gral_deheza.7a8683e35e9d22f7d1d1.jpg",
    img_pc:"https://tienda.zhvirtual.com.ar/static/media/cbu_pc_gral_deheza.edc0ee522713844949e8.jpg"
  }
  ];

  export default ImgsTransfe;