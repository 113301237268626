import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faCircleDown, faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { URL_API } from "../urlApi/urlApi";

const Footer = () => {
  const [data, setData] = useState([]);
  //const [isReadyForInstall, setIsReadyForInstall] = useState(false);


  const datosDescripcion = async () => {
    let codLocal = localStorage.getItem("codLocal");

    try {
      const response = await axios.post(
        URL_API,
        JSON.stringify({
          accion: "obtenerLocales",
          codigo: codLocal,
        })
      );
      if (response.status === 200) {
        let resp = response.data.message;
        setData(resp);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    datosDescripcion();
  }, []);

  /* useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      // Prevent the mini-infobar from appearing on mobile.
      event.preventDefault();
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event;
      // Remove the 'hidden' class from the install button container.
      setIsReadyForInstall(true);
    });
  }, []);
  async function downloadApp() {
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      // The deferred prompt isn't available.
      console.log("oops, no prompt event guardado en window");
      return;
    }
    // Show the install prompt.
    promptEvent.prompt();
    // Log the result
    const result = await promptEvent.userChoice;
    console.log("👍", "userChoice", result);
    // Reset the deferred prompt variable, since
    // prompt() can only be called once.
    window.deferredPrompt = null;
    // Hide the install button.
    setIsReadyForInstall(false);
  } */

  return (
    <div>
      <div className="boton-flotante">
        {data.map((item) => (
          <a
            key={item.descripcion}
            type="button"
            href={`https://api.whatsapp.com/send/?phone=${item.num_local}&text=Tengo+una+consulta+sobre+tu+tienda+online&type=phone_number&app_absent=0`}
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faWhatsapp} style={{ color: "#fff" }} />
          </a>
        ))}
      </div>
      {/* {isReadyForInstall && (
      <div className="boton-flotante-dos">
                      <a
                        type="button"
                        style={{ color: "#fff" }}
                        onClick={downloadApp}
                      >
                        <FontAwesomeIcon
                          icon={faCircleDown}
                          className="iconoMov"
                          style={{ color: "#fff" }}
                        />
                      </a>
                    </div>
                    )} */}
      <footer className="footerTienda"> 
        <div className="row">
          <div className="col-md-8 col-12 contUbi" style={{paddingBottom:"15px"}}>
              <span style={{ fontSize:"14px",color: "#000", fontWeight:"600" }}>Dónde nos encontramos</span>
            
              {data.map((item) => (
                <a
                  key={item.descripcion}
                  className="txtTitu"
                  href={item.maps}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={require("../../assets/854878.png")} style={{width:"22px", marginRight:"5px"}}/>
                  <span style={{ color: "#000", fontSize: "15px" }}>
                    {item.descripcion}
                  </span>
                </a>
              ))}
          </div>

          <div className="col-md-4 col-6 contUbi">
            <span style={{fontSize:"14px" ,color: "#000", fontWeight:"600" }}>
             Contacto 
            </span>
            {data.map((item) => (
              <a
                key={item.descripcion}
                className="txtTitu"
                type="button"
                style={{textDecoration:"none"}}
                href={`https://api.whatsapp.com/send/?phone=${item.num_local}&text=Tengo+una+consulta+sobre+tu+tienda+online&type=phone_number&app_absent=0`}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faWhatsapp} className="iconoWhat" style={{ color: "#000", paddingRight:"5px", fontWeight:"600", fontSize:"18px" }} />
                <span style={{ color: "#000", fontSize: "15px" }}>
                {item.num_local}
                  </span>
              </a>
            ))}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
