import React, { useEffect, useState } from "react";

const CartItem = ({ item, removeFromCart, removeAllFromCart }) => {
  const [removeAll, setRemoveAll] = useState(false);
  let precio = Number(item.precio_uni).toFixed(2)
  useEffect(() => {
    if (item.quantity > 1) {
      setRemoveAll(true);
    } 
    if(item.quantity === 1) {
      setRemoveAll(false);
    }
  },[removeAll]);
  return (
    
    <li
      key={item.idx}
      style={{
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div className="row contItemsCarrito">
        <div className="col-md-1 col-2" style={{paddingLeft:"2px", paddingRight:0}}>
          <img src={item.img} className="imgCarrito"/*  alt={item.nombre} *//> 
        </div>
        <div className="col-md-11 col-10" >
        <div style={{ paddingLeft: "10px" }}>
                  <p className="txtCarritoFrmEnv">
                    x{item.quantity} {item.nom_art}
                  </p>
                  <p className="txtCarritoFrmEnvPrice">
                    {new Intl.NumberFormat("es-AR", {
                      style: "currency",
                      currency: "ARS",
                    }).format(item.precio_uni)}
                  </p>
                </div>
        </div>
        <div className="col-md-11 col-12 contBtnElim">
        <button
          onClick={() => removeFromCart(item)}
          className="btnCartVac"
           style={{
            marginLeft: "10px",
          }} 
          >
          Borrar
        </button>
        {removeAll && (
          <button
          onClick={() => removeAllFromCart(item)}
          className="btnCartVac"
           style={{
            marginLeft: "10px",
          }}
          >
            Borrar Todos
          </button>
        )}
        </div>
      </div>
    </li>
  );
};

export default CartItem;
