import React from "react";
import CartItem from "./CartItem";

const CartItemsList = ({ cartItems, removeFromCart, removeAllFromCart }) => {
  return (
      <ul style={{ listStyle: "none", padding: 0 }}>
        {cartItems.map((item) => (
          <CartItem
            key={item.idx}
            item={item}
            removeFromCart={removeFromCart}
            removeAllFromCart={removeAllFromCart}
          />
        ))}
      </ul>
  );
};

export default CartItemsList;
