import React, { useState, useEffect } from "react";
import axios from "axios";
import { URL_API } from "../urlApi/urlApi";

const CategoryButtons = ({
  selectedType,
  handleTypeChange,
  handleFilterChange,
  setMenuOpen,
}) => {
  const [data, setData] = useState([]);

  // Obtener los datos de la API y actualizar el estado
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let codLocal = localStorage.getItem("codLocal");

    try {
      await axios
        .post(
          URL_API,
          JSON.stringify({
            accion: "obtenerCategoriasTienda",
            cod_local: codLocal,
          }),
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        )
        .then((res) => {
          let resp = res.data;
          setData(resp.message);
        });
    } catch (err) {
    }
  };
  const handleOptionClick = (option) => {
    handleFilterChange(option);
  };
  const handleOptionClickDos = (option) => {
    handleFilterChange(option);
    setMenuOpen(false);
  };

  return (
    // Renderizar los botones
    <div
      style={{
        display: "flex",
        justifyContent: "right",
        paddingRight: "0%",
        paddingTop: "0%",
      }}
    >
      <div className="store__categories contBotones">
        <ul
          style={{
            listStyle: "none",
            paddingLeft: "0",
            textAlign: "left",
            fontFamily: "Arial, sans-serif",
            width: "100%",
          }}
        >
          <div className="contCateg">
            <h6 style={{ fontWeight: "bold" }}>Categorías</h6>
            <hr />
            {data.map((item) => (
              <li key={item.id}>
                <a
                  className="btnCategory"
                  onClick={() => handleTypeChange(item.descripcion)}
                >
                  {item.descripcion}
                </a>
              </li>
            ))}
          </div>
          <div className="contCateg">
            <h6
              style={{
                fontWeight: "bold",
              }}
            >
              Precio
            </h6>
            <hr />
            <li className="btnPc">
              <a
                className="btnCategory"
                onClick={() => handleOptionClick("menor-precio")}
              >
                Menor a Mayor
              </a>
            </li>
            <li className="btnPc">
              <a
                className="btnCategory"
                onClick={() => handleOptionClick("mayor-precio")}
              >
                Mayor a Menor
              </a>
            </li>
            <li className="btnCelular">
              <a
                className="btnCategory"
                onClick={() => handleOptionClickDos("menor-precio")}
              >
                Menor a Mayor
              </a>
            </li>
            <li className="btnCelular">
              <a
                className="btnCategory"
                onClick={() => handleOptionClickDos("mayor-precio")}
              >
                Mayor a Menor
              </a>
            </li>
          </div>
          <div className="contCateg">
            <h6
              style={{
                fontWeight: "bold",
              }}
            >
              Stock
            </h6>
            <hr />
            <li className="btnPc">
              <a
                className="btnCategory"
                onClick={() => handleOptionClick("menor-stock")}
              >
                Menor a Mayor
              </a>
            </li>
            <li className="btnPc">
              <a
                className="btnCategory"
                onClick={() => handleOptionClick("mayor-stock")}
              >
                Mayor a Menor
              </a>
            </li>
            <li className="btnCelular">
              <a
                className="btnCategory"
                onClick={() => handleOptionClickDos("menor-stock")}
              >
                Menor a Mayor
              </a>
            </li>
            <li className="btnCelular">
              <a
                className="btnCategory"
                onClick={() => handleOptionClickDos("mayor-stock")}
              >
                Mayor a Menor
              </a>
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default CategoryButtons;
