import axios from "axios";
import React, { useEffect, useState } from "react";
import { URL_API } from "../urlApi/urlApi";

const SelectGroup = () => {
  const [data, setData] = useState([]);

  useEffect(()=>{
    localStorage.removeItem("codLocal");
  },[])

  // Obtener los datos de la API y actualizar el estado
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let codLocal = localStorage.getItem("codLocal");

    try {
      const response = await axios.post(
        URL_API,
        JSON.stringify({
          accion: "obtenerLocales",
          codigo: codLocal,
        })
      );
      if (response.status === 200) {
        let resp = response.data.message;
        setData(resp);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const habilitados = data.filter(
      (item) =>
        item.codigo_estado  === "1" 
    )
    .sort((a, b) => b.cod_grupo - a.cod_grupo); // Ordenar en forma descendente según los códigos

  const deshabilitados = data
    .filter(
      (item) =>
      item.codigo_estado === "2"
    );

  const botones = [...habilitados, ...deshabilitados];
  const handleClick = (codigo, nombre, img_logo, tiene_socios, descripcion, maps) => {
    // Guardar el código en el localStorage
    localStorage.setItem("codLocal", codigo);
    localStorage.setItem("nombre", nombre);
    localStorage.setItem("img_logo", img_logo);
    localStorage.setItem("tiene_socios", tiene_socios);
    localStorage.setItem("descripcionDirec",descripcion );
    localStorage.setItem("mapsLoc", maps );
    window.location.href = "/Tienda";
  };

  return (
    <div className="contSelectG">
      <div
        style={{ backgroundColor: "#E85217", width: "100%", padding: 8 }}
      ></div>
      <header style={{ background: "#E0E0E0", paddingBottom: "20px" }}>
        <div
          className="row"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            className="col-8"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              src={require("../../assets/zh_tienda.png")}
              style={{ width: "250px", margin: "20px" }}
            />
          </div>

          <div
            className="col-10 col-md-5"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <hr
              style={{
                margin: 0,
                width: "100%",
                border: "1.5px solid #000000",
              }}
            />
          </div>
        </div>
      </header>
      <main
        style={{
          background: "#E0E0E0",
          width: "100%",
          height: "100%",
        }}
      >
        <div className="container" style={{ padding: 0 }}>
          <div
            className="row"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="col-12 col-md-8"
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <h1 style={{ fontSize: "25px", fontWeight: 700 }}>
                Elegí tu punto de venta mas cercano
              </h1>
            </div>
            <div
              className="col-12 col-md-7"
              style={{
                display: "flex",
                justifyContent: "center",
                paddingRight: 0,
              }}
            >
              <div
                className="row"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {botones.map((item) => (
                  <div
                    className="col-6 col-md-4 col-sm-5"
                    key={item.id}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingBottom: "20px",
                    }}
                  >
                    <button
                      className={
                        item.codigo_estado === "2"
                          ? "btnTiendaDisabled"
                          : "btnTienda"
                      }
                      disabled={
                        item.codigo_estado === "2"
                      }
                      onClick={() =>
                        handleClick(item.codigo, item.nombre_loc, item.img_logo, item.tiene_socios, item.descripcion, item.maps)
                      }
                    >
                      {item.nombre_loc}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer
        style={{
          background: "#E0E0E0",
          bottom: 0,
          position: "relative" /* Cambiado a relative */,
          width: "100%",
          paddingTop:"200px"
        }}
      >
        <div className="row">
          <div className="col-12" style={{display:"flex", justifyContent:"center"}}>
            <div
              className="img-container"
              style={{
                display: "flex",
                justifyContent: "center",
                position:"absolute",
                bottom: 0,
                zIndex: 1,
              }}
            >
              <img
                src={require("../../assets/agustinaD.png")}
                className="imgAgus"
              />
            </div>
          </div>
          <div className="col-12 diagonal-div"></div>
        </div>
      </footer>
    </div>
  );
};

export default SelectGroup;

