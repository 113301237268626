import React from "react";
import CategoryButtons from "../Buttons/CategoryButtons ";
import "../../css/style.css";

const NavMenu = ({
  menuOpen,
  selectedType,
  handleTypeChange,
  handleFilterChange,
}) => {
  return (
    <ul className={`nav-menu`} style={{textAlign: "left", paddingTop:0 }}>
      <li>
        <CategoryButtons
          selectedType={selectedType}
          handleTypeChange={handleTypeChange}
          handleFilterChange={handleFilterChange}
        />
      </li>
    </ul>
  );
};

export default NavMenu;
