import React from "react";
import "../css/style.css";
import Cart from "../Components/Cart";

const Inicio = () => {
  return (
    <Cart />
  );
};

export default Inicio;
