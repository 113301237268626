import React from "react";
import NavMenu from "./NavMenu";
import "../../css/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders } from "@fortawesome/free-solid-svg-icons";

const Navbarr = ({
  menuOpen,
  toggleMenu,
  selectedType,
  handleTypeChange,
  handleFilterChange,
}) => {
  return (
    <>
     <nav>
  <ul style={{listStyle:"none", paddingLeft:0}}>
  <li>
  <button className="menu-toggle" onClick={toggleMenu} style={{ fontSize: "15px", top: "5px", position: "relative" }}>

      <span style={{fontWeight:"600" }}><FontAwesomeIcon icon={faSliders}/> Filtrar</span>
  
</button>

    </li>
    <li>
      <NavMenu
        selectedType={selectedType}
        handleTypeChange={handleTypeChange}
        handleFilterChange={handleFilterChange}
      />
    </li> 
  </ul>
</nav>

    </>
  );
};

export default Navbarr;
