import bannerOops from '../../assets/oops.jpg';


const ErrorItemsBanner = () => {
  return (
    <div className="noItemsBanner">
      <img src={bannerOops} alt="opps" />
    </div>
  );
};

export default ErrorItemsBanner;
