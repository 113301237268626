import React, { useEffect, useState } from "react";
import ProductsDataDos from "../../Data/DataDos";
import Carousel from "react-bootstrap/Carousel";

const ContenedorBanners = () => {
  // Variable para almacenar los banners que se mostrarán
  const bannersToDisplay = [];

  // Obtén el valor de codLocal desde localStorage
  const codLocal = localStorage.getItem("codLocal");

  // Itera a través de ProductsDataDos y selecciona los banners adecuados
  ProductsDataDos.forEach((item) => {
    if (item.cod_local === codLocal) {
      bannersToDisplay.push(item);
    }
  });

  // Si no se encontraron banners para el valor de codLocal, muestra los banners con cod_local igual a "9999"
  if (bannersToDisplay.length === 0) {
    ProductsDataDos.forEach((item) => {
      if (item.cod_local === "9999") {
        bannersToDisplay.push(item);
      }
    });
  }

  return (
    <Carousel controls={true} touch={true} data-bs-theme="dark" className="tituloCont">
      {bannersToDisplay.map((item) => (
        <Carousel.Item interval={4000} key={item.id}>
          <img src={item.img} className="d-block w-100" alt="..." />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default ContenedorBanners;
