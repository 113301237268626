import React from 'react'
import SelectGroup from '../Components/SelectGroup/SelectGroup'

const GruposTrabajo = () => {
  return (
    <div>
        <SelectGroup />
    </div>
  )
}

export default GruposTrabajo