const ProductsDataDos = [
    {
      id: 1,
      cod_local: "1",
      img:"https://tienda.zhvirtual.com.ar/static/media/banner-web-prueba.58359448096eee5332a4.jpg"
    },
    {
      id: 2,
      cod_local: "1",
      img:"https://tienda.zhvirtual.com.ar/static/media/banner-web-prueba2.f6c57442b42e817be70c.jpg"
    },
    {
      id: 3,
      cod_local: "1",
      img:"https://tienda.zhvirtual.com.ar/static/media/banner-web3.746384b69063f5fd8566.jpg"
    },
    {
      id: 4,
      cod_local: "2",
      img:"https://tienda.zhvirtual.com.ar/static/media/banner-web-clubzh.9457af1f5afbe020b734.jpg"
    },
    {
      id: 5,
      cod_local: "2",
      img:"https://tienda.zhvirtual.com.ar/static/media/banner-web-clubzh2.829467d167da5b07940c.jpg"
    },{
      id: 6,
      cod_local: "9999",
      img:"https://tienda.zhvirtual.com.ar/static/media/banner-web-prueba.58359448096eee5332a4.jpg"
    },
    {
      id: 7,
      cod_local: "9999",
      img:"https://tienda.zhvirtual.com.ar/static/media/banner-web-prueba2.f6c57442b42e817be70c.jpg"
    },
    {
      id: 8,
      cod_local: "9999",
      img:"https://tienda.zhvirtual.com.ar/static/media/banner-web3.746384b69063f5fd8566.jpg"
    }
  ];

  export default ProductsDataDos;
