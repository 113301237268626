import { faLightbulb } from "@fortawesome/free-regular-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

const Header = ({
  handleCartOpen,
  cartItemCount,
  txtCalcu,
  mostrarBtn,
  handleButton,
}) => {
  return (
    <div className="contHeader">
      <div className="row contDentHea">
        <div
          className={
            mostrarBtn ? "col-8 contIzquierda" : "col-10 contIzquierda"
          }
        >
          <a href="./">
            <img
              src={localStorage.getItem("img_logo")}
              alt="logo"
              className="imgLogo"
            />
          </a>
        </div>
        {mostrarBtn && (
          <div className="col-md-3 col-2 contCent" onClick={handleButton}>
            <div
              style={{
                background: "#F9F4F1",
                color: "#EC4614",
              }}
            >
              <FontAwesomeIcon icon={faLightbulb} className="iconLight" title={txtCalcu} />
              <span
            className="txtLight"
          >
            {txtCalcu}
          </span>
            </div>
          </div>
        )}
        <div className={mostrarBtn ? "col-md-1 col-2 contDerecha" : "col-2 contDerecha"}>
          <div
            style={{
              borderRadius: "50%",
              background: "#F9F4F1",
              height: "40px",
              width: "40px",
              color: "#EC4614",
            }}
          >
            <FontAwesomeIcon
              className="iconCart"
              icon={faCartShopping}
              type="button"
              onClick={handleCartOpen}
            />
            {cartItemCount > 0 && (
              <span className="cart-item-count">{cartItemCount}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
