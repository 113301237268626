import React from "react";
import Product from "./Product";

const ProductList = ({
  searchResults,
  selectedType,
  selectedFilter,
  handleQuantityChange,
  addToCart,
}) => {
  const getFilteredProducts = () => {
    switch (selectedFilter) {
      case "menor-precio":
        return [...searchResults].sort((a, b) => a.precio_uni - b.precio_uni);
      case "mayor-precio":
        return [...searchResults].sort((a, b) => b.precio_uni - a.precio_uni);
      case "menor-stock":
        return [...searchResults].sort((a, b) => a.stk_disp - b.stk_disp);
      case "mayor-stock":
        return [...searchResults].sort((a, b) => b.stk_disp - a.stk_disp);
      case "borrar-filtros":
        return searchResults;
      default:
        return searchResults;
    }
  };

  const filteredProducts = getFilteredProducts();
  const productsWithIdEstado1 = filteredProducts.filter(
    (product) => product.id_estado === "1" && Number(product.stk_disp) > 0
  );
  const productsWithOtherIdEstado = filteredProducts.filter(
    (product) => product.id_estado !== "1" || Number(product.stk_disp) < 1
  );

  const allProducts = [...productsWithIdEstado1, ...productsWithOtherIdEstado];

  return (
    <div className="row" style={{ marginRight: "0%" }}>
      {allProducts
        .filter(
          (product) =>
            selectedType === "todos" || product.categoria === selectedType
        )
        .map((product) => (
          <Product
            key={product.idx}
            product={product}
            handleQuantityChange={handleQuantityChange}
            addToCart={addToCart}
          />
        ))}
    </div>
  );
};

export default ProductList;
