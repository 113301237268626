import bannerOops from '../../assets/no-hay-articulos-cargados.jpg';


const NoItemsBanner = () => {
  return (
    <div className="noItemsBanner">
      <img src={bannerOops} alt="opps" />
    </div>
  );
};

export default NoItemsBanner;
