import React, { useState, useEffect } from "react";
//import Select from "../Select/Select";

const Product = ({ product, addToCart, handleQuantityChange }) => {
  const [maxQuantity, setMaxQuantity] = useState(1);
  let precio = Number(product.precio_uni).toFixed(2);
  let stk = Number(product.stk_disp).toFixed(0);
  const [stkCero, setStkCero] = useState(true);
  const [stkUno, setStkUno] = useState(false);
  const [stkVaci, setStkVaci] = useState(false);
  const [txtBoton, setTxtBoton] = useState("");
  const [carritoLS, setCarritoLS] = useState([]);

  const actualizarCarrito = () => {
    const cartItems = localStorage.getItem("cartItems");
    if (cartItems) {
      // Parsear el valor de localStorage a un objeto JavaScript si es necesario
      const cartItemsObj = JSON.parse(cartItems);
      setCarritoLS(cartItemsObj);
    }
  };

  // Usar useEffect para ejecutar la función cada vez que localStorage cambie
  useEffect(() => {
    actualizarCarrito();
  }, [localStorage.getItem("cartItems")]);

  useEffect(() => {
    if (stk == 0 || stk < 1) {
      setStkCero(false);
      setStkVaci(true);
      setTxtBoton("Sin Stock");
    }
    if (stk > 0) {
      setTxtBoton("Añadir al Carrito");
    }
    if (stk == 1) {
      setStkUno(true);
    }
  }, []);

  useEffect(() => {
    setMaxQuantity(stk);
  }, [stk]);

  const handleSelectChange = (e) => {
    handleQuantityChange({ target: { value: e.target.value } }, product);
  };

  //let codigoArticulo = product.cod_art.padStart(4, "0");
  let codigoArticulo = product.cod_art;

  return (
    <div className="col-6 col-sm-6 col-md-5 col-lg-3 col-xl-3 col-xxl-3 contProducGen">
      <div className="row contGeneralProduc">
        <div className="col-12 contProducts">
          <img className="img-fluid imgProduct" src={product.img} />
        </div>
        <div className="col-12 contInfoPro">
          <div className="row">
            <div className="col-12 contProductsNom">
              <span className="txtNomProduc">
                {product.nom_art.toUpperCase()}
              </span>
            </div>
            <div className="col-12 contProductsNom">
              <span style={{ color: "#575759", fontSize: "10px", lineHeight:"10px"}}>
                {product.det_cbo.toUpperCase()}
              </span>
            </div>
            <div className="col-12 contProductsPre">
              <span className="txtPreProduct">
                {new Intl.NumberFormat("es-AR", {
                  style: "currency",
                  currency: "ARS",
                }).format(precio)}
              </span>
            </div>
            {stkCero && (
              <>
                <div className="col-6 contInput">
                  <span className="txtStkPro">Stock: {stk}</span>
                </div>
                <div
                  className="col-4 col-md-3 contInput"
                  style={{ paddingRight: "0%", paddingBottom: "10px" }}
                >
                  <select
                    className="form-select selectStk"
                    style={{ paddingRight: "15px" }}
                    value={product.quantity}
                    onChange={handleSelectChange}
                    disabled={
                      product.id_estado !== "1" || product.stk_disp === "0"
                    }
                  >
                    {Array.from(
                      { length: maxQuantity },
                      (_, index) => index + 1
                    ).map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}

            <div className="row">
              <div
                className="col-10"
                style={{ display: "flex", justifyContent: "right" }}
              >
                <span className="txtStkPro">
                  {stkUno && "Ultimo Disponible"}
                  {stkVaci && <>&nbsp;</>}
                </span>
              </div>
            </div>
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "right",
                paddingTop: stk < 1 ? "30px" : "",
                paddingRight: "0",
                marginRight: "0"
              }}
            >
              <div
                className="col-12 contBtnAdd"
                style={{ display: "flex", justifyContent: "right" }}
              >
                <button
                  onClick={() => addToCart(product, product.quantity)}
                  className="btn btnAddCart"
                  disabled={product.id_estado !== "1" || stk < 1}
                  //disabled={product.id_estado !== 1 || stk < 1}
                  style={{ height: stk < 1 ? "37px" : "" }}
                >
                  {txtBoton}
                </button>
              </div>
            </div>
            <span
              style={{ color: "#575759", fontSize: "10px", paddingTop: "5px" }}
            >
              {codigoArticulo} - {product.cod_sinc}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
