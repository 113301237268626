import React from "react";

const SearchBar = ({ searchTerm, handleSearchTermChange }) => {
  return (
    <div className="store__search">
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearchTermChange}
        placeholder="Buscar productos..."
      />
    </div>
  );
};

export default SearchBar;
