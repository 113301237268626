import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { URL_API } from "../Components/urlApi/urlApi";
import axios from "axios";
import FormEnv from "../Components/FormEnv/FormEnv";
import ImgsTransfe from "../Data/DataImgTrans";
const urlApiJs = "https://www.zuppahermanos.com.ar/virtual/api";

const FinalizarCompra = () => {
  const [cartItems, setCartItems] = useState([]);
  const [openCart, setOpenCart] = useState(false);
  const [tipoEntrega, setTipoEntrega] = useState(false);
  const [tipoDePago, setTipoDePago] = useState(false);
  const [formularioUr, setFormularioUr] = useState(false);
  const [formularioDr, setFormularioDr] = useState(false);
  const [formularioTr, setFormularioTr] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [tipoPagos, setTipoPagos] = useState([]);
  const [tieneSocios, setTieneSocios] = useState(false);
  const [valCodSoc, setValCodSoc] = useState("");
  const [costoEntrega, setCostoEntrega] = useState("");
  const [colorEntrega, setColorEntrega] = useState("");
  const [localidades, setLocalidades] = useState([]);
  const [costoEn, setCostoEn] = useState([]);
  const [numSocio, setNumSocio] = useState("");
  const [datosSocio, setDatosSocio] = useState([]);
  const [botonActivo, setBotonActivo] = useState(null);
  const [botonActivoDos, setBotonActivoDos] = useState(null);
  const [mapsDirec, setMapsDirec] = useState("");
  const [descripcionDirec, setDescripcionDirec] = useState("");
  const codLocal = localStorage.getItem("codLocal");
  const [values, setValues] = useState({
    accion: "insertaDatosClienteYProductos",
    nom_cliente: "", // validar
    ape_cliente: "", // validar
    dni_cliente: "",
    num_whatsapp: "", // validar
    email: "", // validar
    direccion: "",
    localidad: "",
    productos: "",
    cod_tipo_entrega: "",
    comentario: "",
    importe_total: "",
    cod_local: codLocal,
    cod_socio: "", // Agregar cod_socio con un valor predeterminado
  });
  const [valLocalidad, setValLocalidad] = useState("");
  const [seccion1Completa, setSeccion1Completa] = useState(false);
  const [seccion2Completa, setSeccion2Completa] = useState(false);
  const [seccion3Completa, setSeccion3Completa] = useState(false);
  const [contador, setContador] = useState(1);
  const [desCosto, setDesCosto] = useState("");
  const [valTipoPago, setValTipoPago] = useState("");

  // Función para verificar si todos los campos requeridos de la sección 1 están llenos
  const verificarSeccion1 = () => {
    const camposSeccion1 = [
      values.nom_cliente,
      values.ape_cliente,
      values.dni_cliente,
      values.num_whatsapp
    ];
    const seccion1EstaCompleta = camposSeccion1.every(
      (campo) => campo !== "" && campo !== null
    );
    setSeccion1Completa(seccion1EstaCompleta);
  };

  // Función para verificar si todos los campos requeridos de la sección 2 están llenos
  const verificarSeccion2 = () => {
    if (values.cod_tipo_entrega === "2") {
      const camposSeccion2 = [values.direccion, values.localidad];
      const seccion2EstaCompleta = camposSeccion2.every(
        (campo) => campo !== "" && campo !== null && campo !== " "
      );
      setSeccion2Completa(seccion2EstaCompleta);
      setTipoEntrega(true);
    } else if (values.cod_tipo_entrega === "1") {
      if (values.localidad !== "" || values.direccion !== "") {
        setValues((prevValues) => ({
          ...prevValues,
          localidad: "",
          direccion: "",
        }));
      }
      setValLocalidad("0")
      setSeccion2Completa(true);
      setTipoEntrega(false);
      setCostoEntrega("");
      setDesCosto("");
    }
  };

  // Función para verificar si todos los campos requeridos de la sección 3 están llenos
  const verificarSeccion3 = () => {
    const camposSeccion3 = [values.cod_tipo_pago];
    const seccion3EstaCompleta = camposSeccion3.every(
      (campo) => campo !== "" && campo !== null
    );
    setSeccion3Completa(seccion3EstaCompleta);
    if (values.cod_tipo_entrega === "1") {
      setCostoEntrega("2");
      setDesCosto("");
    }
  };

  useEffect(() => {
    // Obtén los datos del local storage
    const storedCartItems = JSON.parse(localStorage.getItem("cartItems"));

    // Verifica si hay datos en el local storage y establece cartItems
    if (storedCartItems && Array.isArray(storedCartItems)) {
      setCartItems(storedCartItems);
    }
  }, []);

  useEffect(() => {
    // Cargamos los datos desde el Local Storage al montar el componente
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems));
    }
    setIsLoaded(true); // Marcamos que los datos ya han sido cargados desde el Local Storage
  }, []);

  useEffect(() => {
    // Guardamos los datos en el Local Storage cada vez que cartItems cambie (excepto en el montaje inicial)
    if (isLoaded) {
      localStorage.setItem("cartItems", JSON.stringify(cartItems));

      // Actualizar el estado values con los datos de los productos
      const productos = cartItems.map((cartItem) => {
        return {
          precio_unitario: cartItem.precio_uni,
          cantidad: cartItem.quantity,
          cod_art: cartItem.cod_art,
          nom_product: cartItem.nom_art,
          cod_sinc: cartItem.cod_sinc,
          tipo_prod: cartItem.tipo,
        };
      });
      const productosString = productos
        .map((producto) => JSON.stringify(producto))
        .join("¬");

      let numero = cartItems.reduce(
        (total, item) =>
          total + Number(item.precio_uni).toFixed(2) * item.quantity,
        0
      );
      const codLocal = localStorage.getItem("codLocal");

      setValues((prevState) => ({
        ...prevState,
        productos: productosString,
        importe_total: numero,
        cod_local: codLocal,
        cod_cto_ent: costoEntrega,
      }));
    }
  }, [cartItems, isLoaded, costoEntrega]);

  useEffect(() => {
    const tieneSociosLocal = Number(localStorage.getItem("tiene_socios"));
    if (tieneSociosLocal === 1) {
      setTieneSocios(true);
    }
  }, []);
  const [entregaSeleccionada, setEntregaSeleccionada] = useState("");

  const handleSelectEntrega = (value) => {
    setEntregaSeleccionada(value);
    setValues((prevValues) => ({
      ...prevValues,
      cod_tipo_entrega: value,
    }));
  };

  const isChecked = (value) => {
    return entregaSeleccionada === value ? "checked" : "";
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (values.cod_tipo_entrega === "") {
      Swal.fire({
        width: "25em",
        color: "#fff",
        background: "#2B2B2B",
        position: "center",
        icon: "warning",
        title: "Pedido no realizado",
        text: `Porfavor seleccione una opcion antes de confirmar`,
        showConfirmButton: true,
      });
    } else {
      if (valCodSoc === "0" || valCodSoc === "") {
        values.cod_socio = "0"; // Establecer el número de socio como 0
      }

      handleConfirmar(values);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    verificarSeccion1();
    verificarSeccion2();
    verificarSeccion3();
    let selectedText = value; // Por defecto, usamos el valor seleccionado como el texto.
    if (name === "localidad" && value !== null) {
      if (name === "cod_tipo_entrega" && value === "2") {
        let selectElement = value;
        let valuor = selectElement.split("¬")[0];
        let valuorEnt = selectElement.split("¬")[1];
        seleccionarOpcionPorCodigo(valuor);
        setValLocalidad(valuor);
        setCostoEntrega(valuorEnt);
      }else{
        setCostoEntrega("");
        setDesCosto("");
      }
      const [codigo, cod_entrega, texto] = value.split("¬");
      const selectedOption = e.target.options[e.target.selectedIndex];
      setTimeout(() => {
        setCostoEntrega(cod_entrega);
      }, 1000);
      setValLocalidad(codigo);
    }
    if (name === "cod_tipo_pago" && value === "2") {
      setTipoDePago(true);
      setValTipoPago(value);
    }
    if (name === "cod_tipo_pago" && value !== "2") {
      setTipoDePago(false);
      setValTipoPago(value);
    }


    if (name === "cod_tipo_paso" && value === "1") {
      setBotonActivo("1");
      setFormularioUr(true);
      setFormularioDr(false);
      setFormularioTr(false);
    } else if (name === "cod_tipo_paso" && value === "2") {
      setBotonActivo("2");
      setFormularioDr(false);
      setFormularioUr(false);
      setTimeout(() => {
        setFormularioTr(true);
      }, 100);
    } else if (name === "cod_tipo_paso" && value === "3") {
      setBotonActivo("3");
      setFormularioDr(true);
      setFormularioTr(false);
      setFormularioUr(false);
    }

    setValues({
      ...values,
      [name]: selectedText,
    });
  };

  const handleConfirmar = async (values) => {
    // Lógica para realizar la compra
    // Aquí se envían los datos del carrito al servidor.
    try {
      await axios
        .post(
          URL_API,
          JSON.stringify({
            ...values,
          }),
          {
            headers: {
              "Content-type": "text/html; charset=UTF-8",
            },
          }
        )
        .then((response) => {
          //let msj = `Sr. Cliente con estos datos puede consultar su pedido. ${response.data.message}`;
          if (response.data.status === "true") {
            Swal.fire({
              color: "#fff",
              background: "#2B2B2B",
              position: "center",
              icon: "success",
              title: "Pedido realizado con éxito",
              //text: `${msj}`,
              showConfirmButton: true,
              confirmButtonColor: "#e85217",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/Tienda";
              }
            });

            setFormularioUr(false);
            setCartItems([]);
            setTipoEntrega(false);
            setValues({
              accion: "insertaDatosClienteYProductos",
              nom_cliente: "",
              ape_cliente: "",
              dni_cliente: "",
              num_whatsapp: "",
              email: "",
              direccion: "",
              localidad: "",
              productos: "",
              cod_tipo_entrega: "",
              cod_tipo_pago: "",
              comentario: "",
              importe_total: "",
              cod_local: "",
            });
            document.getElementById("formEnv").reset();
          } else {
            Swal.fire({
              width: "20em",
              color: "#fff",
              background: "#2B2B2B",
              title: "Atención! ",
              titleColor: "#E85217",
              text: `${response.data.message}`,
              confirmButtonColor: "#E85217",
            });
          }
        });
    } catch (err) {
      if (!err?.response) {
      }
    }
  };

  const datosTipoPago = async (e) => {
    try {
      await axios
        .post(
          URL_API,
          JSON.stringify({
            accion: "obtenerTiposPagos",
          })
        )
        .then((res) => {
          if (res.status === 200) {
            let resp = res.data.message;
            setTipoPagos(resp);
          }
        });
    } catch (error) {}
  };
  const datosCosto = async (e) => {
    try {
      await axios
        .post(
          URL_API,
          JSON.stringify({
            accion: "obtenerTiposEntregas",
            codigo: "0",
          })
        )
        .then((res) => {
          if (res.status === 200) {
            let resp = res.data.message;
            setCostoEn(resp);
            setColorEntrega(resp.color);
          }
        });
    } catch (error) {}
  };
  const calculateTotal = () => {
    let numero = cartItems.reduce(
      (total, item) =>
        total + Number(item.precio_uni).toFixed(2) * item.quantity,
      0
    );
    let formater = new Intl.NumberFormat("es-AR", {
      style: "currency",
      currency: "ARS",
    }).format(numero);
    return formater;
  };

  useEffect(() => {
    if (contador === 1) verificarSeccion1();
    if (contador === 2) verificarSeccion2();
    if (contador === 3) verificarSeccion3();
  }, [values]);

  const buscarSocio = async (e) => {
    e.preventDefault();
    if (numSocio !== "") {
      try {
        await axios
          .post(
            URL_API,
            JSON.stringify({
              accion: "buscarSocio",
              codSocio: numSocio,
            })
          )
          .then((res) => {
            if (res.status === 200) {
              let resp = res.data.message;
              if (resp != "") {
                verificarSeccion1();
                setDatosSocio(resp);
                document.getElementById("validacion_cliente").value = 1;
                document.getElementById("localidad").value = resp.localidad;
              } else {
                Swal.fire({
                  width: "22em",
                  color: "#fff",
                  icon: "warning",
                  background: "#2B2B2B",
                  title: "Atención! ",
                  titleColor: "#E85217",
                  text: `Número de socio no encontrado`,
                  confirmButtonColor: "#E85217",
                });
                setValues((prevState) => ({
                  ...prevState,
                  cod_socio: "0",
                  nom_cliente: "",
                  ape_cliente: "",
                  dni_cliente: "",
                  num_whatsapp: "",
                  email: "",
                  direccion: "",
                  localidad: "",
                  cod_tipo_pago: "",
                  comentario: "",
                }));
                setValCodSoc("0");
              }
            }
          });
      } catch (error) {}
    } else {
      Swal.fire({
        width: "22em",
        color: "#fff",
        icon: "warning",
        background: "#2B2B2B",
        title: "Atención! ",
        titleColor: "#E85217",
        text: `Ingrese un Número de Socio`,
        confirmButtonColor: "#E85217",
      });
      setValues((prevState) => ({
        ...prevState,
        cod_socio: "0",
        nom_cliente: "",
        ape_cliente: "",
        dni_cliente: "",
        num_whatsapp: "",
        email: "",
        direccion: "",
        localidad: "",
        cod_tipo_pago: "",
        comentario: "",
      }));
      setValCodSoc("0");
    }
  };

  const obtenerLocalidades = async () => {
    try {
      const res = await axios.post(urlApiJs + "/generico.php", {
        accion: "obtenerLocalidadesProvincia",
        provincia: "14",
        top: 10,
        unido: 1,
      });

      const arrLoca = res.data.message;

      // Eliminamos duplicados del array de localidades
      const localidadesUnicas = arrLoca.reduce((acc, localidad) => {
        if (!acc.some((l) => l.codigo === localidad.codigo)) {
          acc.push(localidad);
        }
        return acc;
      }, []);
      setLocalidades(localidadesUnicas);
    } catch (error) {
      console.error("Error al obtener las localidades:", error);
    }
  };

  function seleccionarOpcionPorCodigo(codigo) {
    var selectElement = document.getElementById("localidad");

    for (var i = 0; i < selectElement.options.length; i++) {
      if (selectElement.options[i].value.startsWith(codigo)) {
        // Seleccionar la opción por su índice
        selectElement.selectedIndex = i;

        // Marcar la opción como seleccionada
        selectElement.options[i].selected = true;

        // Mostrar el texto y el valor en la consola (puedes hacer lo que necesites con ellos)
        var textoOpcion = selectElement.options[i].text;
        var valorOpcion = selectElement.options[i].value.split("¬")[0];
        var valorOpcionPre = selectElement.options[i].value.split("¬")[1];

        setValues((prevValues) => ({
          ...prevValues,
          localidad: textoOpcion,
        }));
        setCostoEntrega(valorOpcionPre);
        setValLocalidad(valorOpcion);
        costoEn.map((datos) => {
          if (datos.codigo === valorOpcionPre) {
            setDesCosto(datos.descripcion);
          }
        });

        break; // Salir del bucle una vez que se ha encontrado la opción
      }
    }
  }
  useEffect(() => {
    costoEn.map((datos) => {
      if (datos.codigo === costoEntrega) {
        setDesCosto(datos.descripcion);
      }
      if(valLocalidad === "0"){
        setDesCosto("");
      }
    });
  }, [costoEntrega && valLocalidad]);

  useEffect(() => {
    datosSocio.map((datos) => {
      setValLocalidad(datos.localidad);
      setValues((prevValues) => ({
        ...prevValues,
        nom_cliente: datos.nombre,
        ape_cliente: datos.apellido,
        dni_cliente: datos.dni,
        num_whatsapp: datos.telefono,
        email: datos.email,
        direccion: datos.direccion,
        cod_socio: datos.codigo,
      }));
      setValCodSoc(datos.codigo);
    });
  }, [datosSocio]);
  useEffect(() => {
    localidades.map((date) => {
      if (date.codigo === valLocalidad) {
        setValues((prevValues) => ({
          ...prevValues,
          localidad: date.nombre,
        }));
        setCostoEntrega(date.cod_entrega_tienda);
      }
    });
  }, [valLocalidad]);
  
  const openMenuCart = () => {
    setOpenCart((menuOpen) => !menuOpen);
  };

  const volverTienda = (e) => {
    e.preventDefault();
    window.location.href = "/Tienda";
  };

  const validarBoton = () => {
    if (contador === 1) {
      setBotonActivo("1");
      setFormularioUr(true);
      setFormularioDr(false);
      setFormularioTr(false);
    } else if (contador === 2) {
      setBotonActivo("2");
      setFormularioDr(false);
      setFormularioUr(false);
      setFormularioTr(true);
      if (formularioTr === true) {
        seleccionarOpcionPorCodigo(valLocalidad);
      }
    } else if (contador === 3) {
      setBotonActivo("3");
      setFormularioDr(true);
      setFormularioTr(false);
      setFormularioUr(false);
    }
  };

  const siguientePaso = (e) => {
    e.preventDefault();
    setContador((prevValues) => {
      const nuevoContador = prevValues + 1;
      setBotonActivo(`${nuevoContador}`);
      return nuevoContador;
    });
  };

  const pasoAnterior = (e) => {
    e.preventDefault();
    setContador((prevValues) => {
      const nuevoContador = prevValues - 1;
      setBotonActivo(`${nuevoContador}`);
      return nuevoContador;
    });
  };

  useEffect(() => {
    validarBoton();
  }, [contador]);

  const cartOpenReft = useRef(null);
  useEffect(() => {
    const handleClickOutsideMenu = (event) => {
      if (
        cartOpenReft.current &&
        !cartOpenReft.current.contains(event.target)
      ) {
        setOpenCart(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideMenu);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideMenu);
    };
  }, []);
  useEffect(() => {
    obtenerLocalidades();
    datosTipoPago();
    datosCosto();
    setBotonActivo("1");
    setFormularioUr(true);
    setFormularioTr(false);
    setFormularioDr(false);
    setMapsDirec(localStorage.getItem("mapsLoc"));
    setDescripcionDirec(localStorage.getItem("descripcionDirec"));
  }, []);
  return (
    <div>
      <FormEnv
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        calculateTotal={calculateTotal}
        setNumSocio={setNumSocio}
        values={values}
        setValCodSoc={setValCodSoc}
        tipoEntrega={tipoEntrega}
        localidades={localidades}
        tipoPagos={tipoPagos}
        costoEn={costoEn}
        formularioTr={formularioTr}
        formularioUr={formularioUr}
        buscarSocio={buscarSocio}
        botonActivo={botonActivo}
        cartItems={cartItems}
        openCart={openCart}
        openMenuCart={openMenuCart}
        cartOpenReft={cartOpenReft}
        volverTienda={volverTienda}
        valLocalidad={valLocalidad}
        costoEntrega={costoEntrega}
        colorEntrega={colorEntrega}
        tipoDePago={tipoDePago}
        tieneSocios={tieneSocios}
        formularioDr={formularioDr}
        siguientePaso={siguientePaso}
        pasoAnterior={pasoAnterior}
        botonActivoDos={botonActivoDos}
        seccion1Completa={seccion1Completa}
        seccion2Completa={seccion2Completa}
        seccion3Completa={seccion3Completa}
        handleSelectEntrega={handleSelectEntrega}
        isChecked={isChecked}
        entregaSeleccionada={entregaSeleccionada}
        mapsDirec={mapsDirec}
        descripcionDirec={descripcionDirec}
        desCosto={desCosto}
        valTipoPago={valTipoPago}
      />
    </div>
  );
};

export default FinalizarCompra;
