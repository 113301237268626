import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Offcanvas } from "react-bootstrap";
import {
  faArrowLeft,
  faArrowRight,
  faCartShopping,
  faClose,
  faLocationDot,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import "../../css/style.css";
import ImgsTransfe from "../../Data/DataImgTrans";

const FormEnv = (props) => {
  const {
    handleSubmit,
    handleChange,
    calculateTotal,
    setNumSocio,
    values,
    setValCodSoc,
    tipoEntrega,
    localidades,
    tipoPagos,
    costoEn,
    formularioTr,
    buscarSocio,
    botonActivo,
    cartItems,
    openCart,
    openMenuCart,
    cartOpenReft,
    volverTienda,
    valLocalidad,
    costoEntrega,
    tipoDePago,
    tieneSocios,
    formularioDr,
    siguientePaso,
    pasoAnterior,
    formularioUr,
    seccion1Completa,
    seccion2Completa,
    seccion3Completa,
    handleSelectEntrega,
    isChecked,
    entregaSeleccionada,
    mapsDirec,
    descripcionDirec,
    desCosto,
    valTipoPago
  } = props;
  return (
    <div className="contFormularioGeneral">
      <div className="row" style={{ marginRight: "2px", height: "100%" }}>
        <div className="col-md-8 col-12 formEnv">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <a href="./Tienda">
              <img
                src={localStorage.getItem("img_logo")}
                alt="logo"
                className="imgLogoFormEnv"
              />
            </a>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              paddingTop: "10px",
            }}
          >
            <button className="buttonCartOpenEnv" onClick={openMenuCart}>
              <FontAwesomeIcon
                icon={faCartShopping}
                style={{ paddingRight: "10px" }}
              />
              Ver mi carrito
            </button>
          </div>
          <form
            id="formEnv"
            className="container formEnv"
            onSubmit={handleSubmit}
          >
            <div
              className="row"
              style={{
                paddingBottom: "10px",
                backgroundColor: "#fff",
                paddingRight: "0%",
                marginRight: "0%",
              }}
            >
              <div className=" col-4 contTipoOptions">
                <button
                  className={`btnSeleccion ${
                    botonActivo === "1" ? "btnActivo" : ""
                  }`}
                  name="cod_tipo_paso"
                  value="1"
                  type="button"
                  onClick={handleChange}
                  disabled
                >
                  1. Datos
                </button>
              </div>
              <div className=" col-4 contTipoOptions">
                <button
                  className={`btnSeleccion ${
                    botonActivo === "2" ? "btnActivo" : ""
                  }`}
                  name="cod_tipo_paso"
                  value="2"
                  type="button"
                  onClick={handleChange}
                  disabled
                >
                  2. Forma de Entrega
                </button>
              </div>
              <div className=" col-4 contTipoOptions">
                <button
                  className={`btnSeleccion ${
                    botonActivo === "3" ? "btnActivo" : ""
                  }`}
                  name="cod_tipo_paso"
                  value="3"
                  type="button"
                  onClick={handleChange}
                  disabled
                >
                  3. Forma de Pago
                </button>
              </div>
              {formularioUr && (
                <div
                  className="row cardEntrega checked"
                  style={{ paddingLeft:"0%", marginRight: "0%",paddingBottom:"25px",cursor: "default", background:"#fff"  }}
                >
                  <div
                    className="col-12 formEnv"
                    style={{ justifyContent: "center" }}
                  >
                    <p
                      style={{
                        marginTop: "15px",
                        fontSize: ".875rem",
                        fontWeight: "700",
                        textTransform: "uppercase",
                      }}
                    >
                      Datos del destinatario
                    </p>
                  </div>
                  {tieneSocios && (
                    <div className="col-md-7 col-12 d-flex flex-column formEnv">
                      <label htmlFor="txtDni" className="form-label">
                        N° Socio&nbsp;
                        <small style={{ fontWeight: "500" }}>(Opcional)</small>
                      </label>
                      <div className="d-flex align-items-center contBuscarSocio">
                        <input
                          id="txtNumSocio"
                          name="cod_socio"
                          type="number"
                          className="form-input inputBuscarSocio inputCentrado"
                          placeholder="Número"
                          inputMode="numeric"
                          value={values.cod_socio}
                          onChange={(e) => {
                            setNumSocio(e.target.value);
                            handleChange(e);
                          }}
                        />
                        <a
                          style={{
                            paddingLeft: "3px",
                            cursor: "pointer",
                            borderLeft: "1px solid #CCCCCC",
                            height: "100%",
                          }}
                          onClick={buscarSocio}
                        >
                          <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            className="ml-2"
                            style={{
                              color: "#525252",
                              position: "relative",
                              top: "20%",
                              padding: "4px 6px 4px 4px",
                            }}
                          />
                        </a>
                      </div>
                      <input
                        type="hidden"
                        id="validacion_cliente"
                        onChange={(e) => setValCodSoc(e.target.value)}
                      />
                    </div>
                  )}
                  <div
                    className="col-md-6 col-6"
                    style={{ paddingRight: "0%" }}
                  >
                    <label htmlFor="txtNom" className="form-label">
                      Nombre
                    </label>
                    <input
                      id="txtNom"
                      name="nom_cliente"
                      required
                      className="form-input"
                      placeholder="Nombre"
                      value={values.nom_cliente}
                      onChange={handleChange}
                    />
                  </div>
                  <div
                    className="col-md-6 col-6"
                    style={{ paddingRight: "0%" }}
                  >
                    <label htmlFor="txtApell" className="form-label">
                      Apellido
                    </label>
                    <input
                      required
                      id="txtApell"
                      name="ape_cliente"
                      className="form-input"
                      placeholder="Apellido"
                      value={values.ape_cliente}
                      onChange={handleChange}
                    />
                  </div>
                  <div
                    className="col-md-6 col-6"
                    style={{ paddingRight: "0%" }}
                  >
                    <label htmlFor="txtDni" className="form-label">
                      DNI
                    </label>
                    <input
                      required
                      id="txtDni"
                      name="dni_cliente"
                      type="number"
                      className="form-input inputCentrado"
                      placeholder="DNI"
                      value={values.dni_cliente}
                      onChange={handleChange}
                    />
                  </div>
                  <div
                    className="col-md-6 col-6"
                    style={{ paddingRight: "0%" }}
                  >
                    <label className="form-label" htmlFor="txtNum">
                      Número de Teléfono
                    </label>
                    <input
                      required
                      id="txtNum"
                      name="num_whatsapp"
                      className="form-input inputCentrado"
                      placeholder="3572999999"
                      type="number"
                      value={values.num_whatsapp}
                      onChange={handleChange}
                    />
                  </div>
                  <div
                    className="col-md-7 col-12"
                    style={{ paddingRight: "0%" }}
                  >
                    <label className="form-label" htmlFor="txtEmail">
                      Email
                    </label>
                    <input
                      id="txtEmail"
                      name="email"
                      type="email"
                      className="form-input"
                      placeholder="ejemplo@ejemplo.com"
                      value={values.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              )}
              {formularioTr && (
                <div
                  className="row"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "20px",
                  }}
                >
                  <div
                    className="col-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "15px",
                    }}
                  >
                    <h4>Seleccione una Opción</h4>
                  </div>
                  <div
                    className={`cardEntrega ${isChecked("1")}`}
                    onClick={() => handleSelectEntrega("1")}
                  >
                    <div>
                      <input
                        type="checkbox"
                        className="radio-input"
                        id="domicilioFalse"
                        name="cod_tipo_entrega"
                        value="1"
                        onChange={handleChange}
                        checked={entregaSeleccionada === "1"}
                      />
                      <label
                        className="radio-label labelTipoRet"
                        htmlFor="domicilioFalse"
                      >
                        Retiro por Local
                      </label>
                    </div>
                    <a
                      href={mapsDirec}
                      target="_blank"
                      style={{ width: "100%" }}
                    >
                     <FontAwesomeIcon icon={faLocationDot} style={{paddingRight:"5px"}} color="#E11919"/> 
                     {descripcionDirec}
                    </a>
                  </div>
                  <div
                    className={`cardEntrega ${isChecked("2")}`}
                    onClick={() => handleSelectEntrega("2")}
                    
                  >
                    <div>
                      <input
                        type="checkbox"
                        className="radio-input"
                        name="cod_tipo_entrega"
                        id="domicilioTrue"
                        value="2"
                        onChange={handleChange}
                        checked={entregaSeleccionada === "2"}
                      />
                      <label
                        className="radio-label labelTipoRet"
                        htmlFor="domicilioTrue"
                      >
                        {" "}
                        Envío a Domicilio
                      </label>
                    </div>
                    <div className="row">
                      <div
                        className="col-6"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            marginTop: "25px",
                            fontSize: ".875rem",
                            fontWeight: "700",
                            textTransform: "uppercase",
                          }}
                        >
                          Domicilio del destinatario{" "}
                        </p>
                      </div>
                      <div className="col-6">
                        <p
                          style={{
                            marginTop: "25px",
                            marginBottom: "5px",
                            paddingRight: "25px",
                            fontWeight: "600",
                            fontSize: ".775rem",
                          }}
                        >
                          Costo de Entrega:{" "}
                        </p>{" "}
                        <p
                          style={{
                            fontSize: ".675rem",
                            fontWeight: "700",
                            textTransform: "uppercase",
                            color:
                              costoEntrega === "2"
                                ? "#36c41f"
                                : costoEntrega === "1"
                                ? "#e89f0c"
                                : "black",
                          }}
                        >
                          {desCosto}
                        </p>
                      </div>
                      <div
                        className="col-md-6 col-12"
                        style={{ paddingRight: "0%" }}
                      >
                        <label htmlFor="txtDirec" className="form-label">
                          Dirección
                        </label>
                        <input
                          required
                          id="txtDirec"
                          name="direccion"
                          className="form-input"
                          placeholder="Dirección"
                          value={values.direccion}
                          onChange={handleChange}
                        />
                      </div>
                      <div
                        className="col-md-6 col-12"
                        style={{ paddingRight: "0%" }}
                      >
                        <label className="form-label" htmlFor="txtNum">
                          Localidad
                        </label>
                        <select
                          required
                          name="localidad"
                          id="localidad"
                          className="form-input"
                          style={{ color: "#000", background: "#fff" }}
                          aria-describedby="sizing-addon3"
                          onChange={handleChange}
                          selected={valLocalidad}
                        >
                          <option value="0" disabled={valLocalidad !== "0"} selected>
                            Seleccionar...
                          </option>
                          {localidades.map((localidad) => (
                            <option
                              key={localidad.codigo}
                              value={`${localidad.codigo}¬${localidad.cod_entrega_tienda}¬${localidad.nombre}`}
                              selected={localidad.codigo === valLocalidad}
                            >
                              {localidad.nombre}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {formularioDr && (
                <>
                  <div
                    className={`row cardEntrega checked`}
                    style={{ cursor: "default", background:"#fff" }}
                  >
                    {tipoEntrega && (
                    <div
                      className="col-md-6 col-12"
                      style={{ paddingRight: "0%" }}
                    >
                      <label className="form-label" htmlFor="txtTipPago">
                        Precio del Envío
                      </label>
                      <select
                        disabled
                        name="cod_entrega_tienda"
                        id="cod_entrega_tienda"
                        className="form-input"
                        aria-describedby="sizing-addon3"
                        onChange={handleChange}
                        value={costoEntrega}
                        style={{  color:
                          costoEntrega === "2"
                            ? "#36c41f"
                            : costoEntrega === "1"
                            ? "#e89f0c"
                            : "black",
                       fontWeight: "600" }}
                      >
                        <option value=""></option>
                        {costoEn.map((costo) => (
                          <option key={costo.codigo} value={costo.codigo}>
                            {costo.descripcion}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                    <div
                      className="col-md-6 col-12"
                      style={{ paddingRight: "0%" }}
                    >
                      <label className="form-label" htmlFor="txtTipPago">
                        Tipo de Pago
                      </label>
                      <select
                        required
                        className="form-input"
                        name="cod_tipo_pago"
                        id="txtTipPago"
                        style={{ color: "#000", background: "#fff" }}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>
                          Seleccionar...
                        </option>
                        {tipoPagos.map((tpago) => {
                          if (tpago.id !== "3") {
                            return (
                              <option key={tpago.id} value={tpago.id} selected={tpago.id === valTipoPago}>
                                {tpago.descripcion}
                              </option>
                            );
                          }
                        })}
                      </select>
                    </div>
                    
                    <div
                      className="col-md-12 col-12"
                      style={{ paddingRight: "0%", marginTop: "25px" }}
                    >
                      <label className="form-label" htmlFor="txtComent">
                        Comentario
                      </label>
                      <textarea
                        className="form-input"
                        name="comentario"
                        id="txtComent"
                        placeholder="Comentario (Limite de caracteres 100)"
                        maxLength={100}
                        rows={2}
                        value={values.comentario}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  
                  {tipoDePago && (
                      <div
                        className="col-md-6 col-12"
                        style={{ paddingRight: "0%", paddingTop: "10px" }}
                      >
                        {ImgsTransfe.map((item) => {
                          if (
                            item.cod_local === localStorage.getItem("codLocal")
                          ) {
                            return (
                              <div key={item.id}>
                                <img
                                  src={item.img_cel}
                                  style={{ width: "100%" }}
                                  className="imgTranfCel"
                                />
                                <img
                                  src={item.img_pc}
                                  style={{ width: "100%" }}
                                  className="imgTranfPc"
                                />
                              </div>
                            );
                          }
                        })}
                      </div>
                    )}
                </>
              )}

              <div className="col-12 contenedorBotones">
                {formularioDr && (
                  <Button
                    className="form-button"
                    variant="primary"
                    type="submit"
                    disabled={!seccion3Completa && botonActivo === "3"}
                  >
                    Confirmar
                  </Button>
                )}
                {formularioUr && (
                  <button
                    className="btnVolverTienda"
                    type="button"
                    onClick={volverTienda}
                  >
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={{ paddingRight: "10px" }}
                    />
                    Seguir Comprando
                  </button>
                )}
                {!formularioUr && (
                  <button
                    className="btnVolverTienda"
                    type="button"
                    onClick={pasoAnterior}
                  >
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={{ paddingRight: "10px" }}
                    />
                    Paso Anterior
                  </button>
                )}
                {!formularioDr && (
                  <button
                    className="btnVolverTienda"
                    type="button"
                    onClick={siguientePaso}
                    disabled={
                      (!seccion1Completa && botonActivo === "1") ||
                      (!seccion2Completa && botonActivo === "2")
                    }
                  >
                    Siguiente Paso
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      style={{ paddingLeft: "10px" }}
                    />
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
        {
          <Offcanvas show={openCart} onHide={openMenuCart} placement="end">
            {
              <div className={`row cart-overlay`}>
                <div className={`col-12 cart-container`}>
                  <div ref={cartOpenReft} className="contCartEnvioCel">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "right",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "30px",
                          height: "20px",
                          position: "relative",
                          cursor: "pointer",
                          top: "5px",
                        }}
                        type="button"
                        onClick={openMenuCart}
                      >
                        <FontAwesomeIcon icon={faClose} className="iconX" />
                      </div>
                    </div>
                    <div
                      className="contCartEnv "
                      style={{ backgroundColor: "#FBFBFB" }}
                    >
                      <div className="contCartEnvDos">
                        <p className="txtTitCartEnv">Tu Carrito</p>
                        {cartItems.map((item) => (
                          <li
                            key={item.idx}
                            style={{
                              marginTop: "15px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={item.img}
                              className="imgCarritoEnv" /*  alt={item.nombre} */
                            />
                            <div style={{ paddingLeft: "10px" }}>
                              <p className="txtCarritoFrmEnv">
                                x{item.quantity} {item.nom_art}
                              </p>
                              <p className="txtCarritoFrmEnvPrice">
                                {new Intl.NumberFormat("es-AR", {
                                  style: "currency",
                                  currency: "ARS",
                                }).format(item.precio_uni)}
                              </p>
                            </div>
                          </li>
                        ))}
                        <hr style={{ margin: "50px 0 20px 0" }} />
                        <div className="contTotalAPagar">
                          <p className="txtTotalAPagar">Total</p>
                          <p className="txtTotalAPagar">{calculateTotal()}</p>
                        </div>
                        {tipoEntrega && (
              <div className="contTotalAPagar">
                <p
                  style={{
                    marginTop: "25px",
                    marginBottom: "5px",
                    paddingRight: "25px",
                    fontWeight: "600",
                    fontSize: ".775rem",
                  }}
                >
                  Costo de Entrega:{" "}
                </p>{" "}
                <p
                  style={{
                    marginTop: "25px",
                    fontSize: ".775rem",
                    fontWeight: "700",
                    textTransform: "uppercase",
                    marginBottom: "5px",
                    color:
                      costoEntrega === "2"
                        ? "#36c41f"
                        : costoEntrega === "1"
                        ? "#e89f0c"
                        : "black",
                  }}
                >
                  {desCosto}
                </p>
              </div>
            )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </Offcanvas>
        }
        <div className="contCartEnv displayNoneEnv">
          <div className="contCartEnvDos">
            <p className="txtTitCartEnv">Tu Carrito</p>
            {cartItems.map((item) => (
              <li
                key={item.idx}
                style={{
                  marginTop: "15px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={item.img}
                  className="imgCarritoEnv" /*  alt={item.nombre} */
                />
                <div style={{ paddingLeft: "10px" }}>
                  <p className="txtCarritoFrmEnv">
                    x{item.quantity} {item.nom_art}
                  </p>
                  <p className="txtCarritoFrmEnvPrice">
                    {new Intl.NumberFormat("es-AR", {
                      style: "currency",
                      currency: "ARS",
                    }).format(item.precio_uni)}
                  </p>
                </div>
              </li>
            ))}
            <hr style={{ margin: "50px 0 20px 0" }} />
            <div className="contTotalAPagar">
              <p className="txtTotalAPagar">Total</p>
              <p className="txtTotalAPagar">{calculateTotal()}</p>
            </div>
            {tipoEntrega && desCosto && (
              <div className="contTotalAPagar">
                <p
                  style={{
                    marginTop: "25px",
                    marginBottom: "5px",
                    paddingRight: "25px",
                    fontWeight: "600",
                    fontSize: ".775rem",
                  }}
                >
                  Costo de Entrega:{" "}
                </p>{" "}
                <p
                  style={{
                    marginTop: "25px",
                    fontSize: ".775rem",
                    fontWeight: "700",
                    textTransform: "uppercase",
                    marginBottom: "5px",
                    color:
                      costoEntrega === "2"
                        ? "#36c41f"
                        : costoEntrega === "1"
                        ? "#e89f0c"
                        : "black",
                  }}
                >
                  {desCosto}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEnv;
