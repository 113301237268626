import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Inicio from "./Pages/Inicio";
import GruposTrabajo from "./Pages/GruposTrabajo";
import FinalizarCompra from "./Pages/FinalizarCompra";
import ImgTranf from "./Pages/imgs";



function App() {
  return (
    <Router>
    <Routes>
      <Route exact path="/" element={<GruposTrabajo/>}></Route>
      <Route exact path="/Tienda" element={<Inicio/>}></Route>
      <Route exact path="/FormEnv" element={<FinalizarCompra/>}></Route>
      <Route exact path="/ImgTranf" element={<ImgTranf/>}></Route>
    </Routes>
  </Router>
  );
}

export default App;
